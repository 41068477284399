import React from "react";
import "../LoanPage/LoanPage.css";

const About = () => {
  const aboutData = {
    titleSection: "About Us",
    sections: [
      {
        heading: "Who We Are",
        texts: [
          "We are a team of seasoned professionals specialized in debt syndication and loan services, offering tailored solutions to meet the unique financial needs of businesses and individuals. With extensive expertise, we assist clients in accessing a range of loan options and streamlining the documentation process to ensure the best possible outcomes.",
          "In addition, we provide payroll management services, ensuring seamless salary processing, tax compliance, and employee benefits administration, so businesses can focus on growth while we handle the details.",
          "At Brick Solutions, we are committed to being your trusted partner, delivering reliable and efficient financial solutions to help you achieve your goals.          ",
        ],
        list: null,
      },
      {
        heading: "Our Services",
        texts: [
          "We offer a range of services designed to meet the financial and Payroll needs of the clients. These include:",
        ],
        list: [
          {
            heading: "1. Loan Services",
            description:
              "Brick Solutions collaborates with leading banks and financial institutions to help clients secure a variety of loan options:",
            details: [
              "Business Loans",
              "Overdraft Facility and Cash Credit",
              "Home Loans",
              "Loans Against Property",
              "Auto Loans",
              "Personal Loans",
              "Loan Takeover",
            ],
          },
          {
            heading: "2. Financial Consultancy",
            description:
              "We provide expert guidance to help businesses enhance their financial health through services like debt consolidation, Strategic debt management and working capital optimization.",
            details: null,
          },
          {
            heading: "3. Payroll Solutions",
            description:
              "Our payroll solutions offer comprehensive management of salary processing, tax compliance, and employee benefits, ensuring accuracy, timeliness, and adherence to regulations. We handle the entire payroll process, allowing businesses to focus on growth while ensuring employees are paid correctly and on time.",
            details: [
              "<b>End-to-end payroll management:</b> Comprehensive handling of salary processing, tax compliance, and benefits administration.",
              "<b>Timely and accurate:</b> Ensures employees are paid correctly and on schedule.",
              "<b>Tax compliance:</b> Adheres to local and national tax regulations.",
              "<b>Employee benefits management:</b> Efficient handling of benefits like PF, ESI, employee tax structuring and other benefits",
              "<b>Focus on growth:</b> Allows businesses to concentrate on their core activities while we manage payroll.",
            ],
          },
        ],
      },
      {
        heading: "Why Choose Us?",
        texts: [
          "Here are the reasons why Brick Solutions is the right choice for you:",
        ],
        list: [
          {
            details: [
              "<b>Over 15+ years of expertise:</b> With nearly two decades of experience, we bring deep industry knowledge to every service we offer.",
              "<b>Comprehensive financial and HR services:</b> We specialize in debt syndication, loan services, payroll solutions, and financial consultancy, catering to both businesses and individuals.",
              "<b>Experienced Team:</b> A leadership team with decades of experience in banking, finance, and HR solutions.",
              "<b>Trusted partnerships:</b> We collaborate with top banks and financial institutions, offering a wide range of loan options to suit different needs.",
              "<b>Trusted by Industry Leaders:</b> Trusted by clients like Google, Microsoft, Steadfast Medishield Private Limited and Map My India for manpower outsourcing and financial services.",
              "<b>Customized Solutions:</b> Tailored services to address unique business and individual requirements. Our services are customized to meet your specific financial and workforce management needs, ensuring the best outcomes for you",
              "<b>Seamless payroll management:</b> We handle everything from salary processing to tax compliance, allowing businesses to focus on growth while we manage the details.",
              "<b>Reliable and efficient:</b> Our commitment to delivering reliable, efficient, and customized services ensures you achieve your financial goals with confidence and ease.",
              "<b>Compliance Focus:</b> Adherence to GST, PF, ESI, and TDS regulations to ensure hassle-free operations.",
            ],
          },
        ],
      },
      {
        heading: "Our Mission",
        texts: [
          "At Brick Solutions, our mission is to simplify financial and workforce management for businesses and individuals. With over 17 years of expertise, we are dedicated to provide tailored solutions in debt syndication, loan services, payroll management, and financial consultancy. We empower our clients to achieve their goals with confidence by offering reliable, efficient, and customized services that drive growth and financial well-being.",
        ],
        list: null,
      },
    ],
  };

  return (
    <div className="loan-page-container">
      {/* <h1 className="headingLoan" style={{ paddingTop: "80px" }}>{aboutData.titleSection}</h1> */}
      <div className="loancontainer">
        <div className="loan-content">
          <h1 className="loan-heading">About Us</h1>
          <p className="loan-description">
            Welcome to Brick Solutions, established in 2007. With over 17 years
            of industry expertise, we specialize in debt syndication, loan
            services, financial consultancy, payroll solutions, and, all
            tailored to meet the diverse needs of the clients.
          </p>
          <p className="loan-description">
            We pride ourselves on delivering reliable, efficient, and customized
            services to help businesses and individuals achieve their financial
            goals.
          </p>
          {/* <button className="apply-button">buttonText</button> */}
        </div>
        <div className="loan-image">
          <img src="./about.png" alt="Loan Assistance" className="loan-img" />
        </div>
      </div>
      <div
        className="loan-form-container"
        style={{
          flexDirection: "column",
          paddingTop: "20px",
          paddingBottom: "0",
        }}
      >
        {aboutData.sections?.map((section, index) => (
          <div key={index}>
            <h2 className="headingContainerLoan">{section.heading}</h2>
            <div className="terms-boxLoan">
              {section.texts.map((text, textIndex) => (
                <div
                  key={textIndex}
                  className="textLoan"
                  style={{ textAlign: "justify" }}
                >
                  {text}
                </div>
              ))}
              {section.list && (
                <div>
                  {section.list.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      {item.heading && <h3>{item.heading}</h3>}
                      {item.description && <p>{item.description}</p>}
                      {item.details && item.details.length > 0 && (
                        <ul className="listLoan">
                          {item.details.map((detail, detailIndex) => (
                            <li
                              key={detailIndex}
                              dangerouslySetInnerHTML={{ __html: detail }}
                            ></li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
