import React from 'react'
import Hero from './Hero/Hero'
import BankImageList from './BankImageList/BankImageList'
import Detail from './Detail/Detail'
import LoanEMICalculator from './LoanEMI/LoanEMI'
import OurPartners from './OurPartner/OurPartner'
import LoanSection from './LoanSection/LoanSection'
import HomeContact from './HomeContact/HomeContact'

function Home() {
  return (
    <div>
      <Hero />
      <OurPartners/>
      <LoanEMICalculator/>
      <LoanSection/>
      <HomeContact/>
      {/* <BankImageList/> */}
      {/* <Detail/> */}
    </div>
  )
}

export default Home