import React, { useEffect, useState } from "react";
import "./blog.css";
// import { blog } from "./dummydata";
import Heading from "./Heading";
import Slider from "react-slick";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";

const Blog = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [selectedTag, setSelectedTag] = useState();

  useEffect(() => {
    fetchTag();
  }, []);

  useEffect(() => {
    fetchBlog();
  }, [selectedTag]);

  const fetchTag = async () => {
    let response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/blog/getTag`
    );
    let blogTag = response.data.data.tags.map((tag) => {
      return {
        title: tag.tagName,
        id: tag._id,
      };
    });
    setTags(blogTag);
  };

  const fetchBlog = async () => {
    let query = "";
    if (selectedTag) {
      query = `?tag=${selectedTag}`;
    }
    let response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/blog/getAll${query}`
    );
    setBlogs(response.data.data.blog);
  };

  const handleNavigate = (id) => {
    navigate("/blogDetail/" + id);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < tags.length - 4) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <div className="blogmaincontainer">
      {/* <Heading subtitle='OUR BLOG' title='Recent From Blog' /> */}
      <h1 className="headingBlognew">OUR BLOG</h1>
      <div className="carousel-section">
        <div className="heading-section">
          <h2>
            Choose by <span style={{ color: "#f37e20" }}>Category</span>
          </h2>
        </div>
        <div className="carousel-container">
          {currentIndex > 0 && (
            <button className="arrow-btn left" onClick={handlePrev}>
              <IoIosArrowDropleft />
            </button>
          )}
          <div className="card-carousel">
            {tags.slice(currentIndex, currentIndex + 4).map((card) => (
              <div
                key={card.id}
                className={
                  selectedTag === card.id ? "card card-selected" : "card"
                }
                onClick={() => {
                  setSelectedTag(card.id);
                }}
              >
                <h3>{card.title}</h3>
              </div>
            ))}
          </div>
          {currentIndex + 4 < tags.length && (
            <button className="arrow-btn right" onClick={handleNext}>
              <IoIosArrowDropright />
            </button>
          )}
        </div>
      </div>

      <h2 className="subheadingBlognew">Only Blogs and Articles </h2>
      <section className="blog">
        <div className="container">
          <div className="grid2">
            {blogs.map((val) => {
              const maxLength = 150; // Limit the description to 150 characters
              const shortDescription =
                val.shortDescription.length > maxLength
                  ? val.shortDescription.slice(0, maxLength) + "..."
                  : val.shortDescription;

              return (
                <div
                  className="items shadow"
                  onClick={() => {
                    handleNavigate(val._id);
                  }}
                >
                  <div className="img">
                    <img src={val.thumbnail} alt={val.title} />
                  </div>
                  <div className="text">
                    <div className="admin flexSB">
                      <span>
                        <label htmlFor="" style={{ margin: "0px" }}>
                          {val.tag.tagName}
                        </label>
                      </span>
                      <span>
                        <label htmlFor="">
                          {moment(val.createdAt).format("DD-MM-YYYY")}
                        </label>
                      </span>
                    </div>
                    <h1>{val.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: shortDescription }} />
                    {val.shortDescription.length > maxLength && (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleNavigate(val._id);
                        }}
                      >
                        Read More
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
