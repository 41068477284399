import React from "react";
import StaticPage from "./StaticPage";

const Terms = () => {
  const termsData = {
    title: "Terms and Conditions",
    sections: [
      {
        heading: "Introduction",
        texts: [
          'This document outlines the terms and conditions ("Agreement") governing the use of loan and payroll processing services provided by Brick Solutions ("Brick Solutions," "we," "us," or "our"). By using our services, you ("User" or "You") agree to comply with these terms.',
        ],
      },
      {
        heading: "Consent to Access Credit Information",
        detailList: [
          {
            heading:
              '<b>Authorization to Access Credit Information:</b> You hereby consent to Brick Solutions. being appointed as your authorized representative to access your Credit Information, including Credit Information Reports, Credit Scores, aggregates, inferences, and related data ("Credit Information").',
            details: [
              'This authorization is valid for a period of 1 Year from the date consent is provided or until the intended purpose ("End Use Purpose") is fulfilled, whichever occurs earlier.',
              "The Credit Information will be used solely for processing loan applications and verifying eligibility.",
            ],
          },
          {
            heading:
              "<b>Limited Use:</b> We will access and use your Credit Information exclusively for the purpose of evaluating loan eligibility, financial assessment, and related credit services.",
            details: [],
          },
          {
            heading: "<b>Data Retention and Purging</b>",
            details: [
              "Any documents or information shared with us will remain strictly confidential and will not be disclosed to unauthorized third parties, except as required for specified loan processing and financial service purposes, in compliance with applicable regulations",
            ],
          },
          {
            heading: "<b>Confidentiality</b>",
            details: [
              "Brick Solutions ensures the confidentiality of your Credit Information both online and offline.",
              "Information shared with us will not be disclosed to unauthorized third parties.",
            ],
          },
        ],
      },
      {
        heading: "Terms for Payroll Processing Services",
        detailList: [
          {
            heading:
              "<b>Employee Data Management:</b> By availing of our payroll services, you authorize us to process employee details, including salary information, tax-related data, and statutory compliance records (e.g., PF, ESI, and TDS).",
            details: [],
          },
          {
            heading: "<b>Data Confidentiality</b>",
            details: [
              "All payroll-related information will remain strictly confidential.",
              "Brick Solutions will ensure that employee data is accessed and processed only for the purpose of salary disbursement, tax compliance, and generating required reports.",
            ],
          },
          {
            heading: "<b>Data Retention and Security</b>",
            details: [
              "Payroll records will be securely stored in compliance with legal and regulatory requirements.",
              "Data will be retained only for the duration required by applicable laws, after which it will be permanently erased or anonymized.",
            ],
          },
        ],
      },
      {
        heading: "General Terms Applicable to Both Services",
        detailList: [
          {
            heading: "<b>User Responsibilities</b>",
            details: [
              "Provide accurate and up-to-date information for processing loans and payroll services.",
              "Notify us immediately of any discrepancies or unauthorized access.",
            ],
          },
          {
            heading: "<b>Governing Law and Jurisdiction</b>",
            details: [
              "This Agreement is governed by the laws of India.",
              "All disputes will be subject to the exclusive jurisdiction of the courts of Delhi.",
            ],
          },
          {
            heading: "<b>Electronic Records and Consent</b>",
            details: [
              "This document is an electronic record under the Information Technology Act, 2000 and related rules.",
              "By using our services, you consent to the terms outlined herein.",
            ],
          },
          {
            heading: "<b>Limitations of Liability</b>",
            details: [
              "Brick Solutions is not liable for any indirect or consequential damages arising from the use of our services.",
              "Our liability is limited to the extent of the services provided.",
            ],
          },
          {
            heading: "<b>Amendments</b>",
            details: [
              "Brick Solutions reserves the right to amend these terms at any time.",
              "Continued use of our services implies acceptance of the updated terms.",
            ],
          },
        ],
      },
      {
        heading: "Contact Information",
        texts: [
          "For any queries or concerns regarding these terms or our services, contact us at:",
        ],
        list: [
          "Grievance Officer, Brick Solutions",
          "A-203-204-205, R.G Complex, Motia Khan, Paharganj, New Delhi-110055",
          "bricksolutions.web@gmail.com",
        ],
      },
      {
        heading: "Effective Date",
        texts: ["Effective Date: 26 Febuary 2025"],
      },
    ],
  };

  return (
    <div>
      <StaticPage title={termsData.title} sections={termsData.sections} />
    </div>
  );
};

export default Terms;
