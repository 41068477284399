import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const LoanEMICalculator = () => {
  const [loanAmount, setLoanAmount] = useState(500000);
  const [interestRate, setInterestRate] = useState(8);
  const [tenure, setTenure] = useState(12);

  const calculateEMI = () => {
    const monthlyRate = interestRate / 12 / 100;
    const emi =
      (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, tenure)) /
      (Math.pow(1 + monthlyRate, tenure) - 1);
    return Math.round(emi);
  };

  const emi = calculateEMI();
  const totalPayment = Math.round(emi * tenure);
  const interestAmount = Math.round(emi * tenure - loanAmount);

  const chartData = {
    labels: ["Principal Amount", "Interest Amount"],
    datasets: [
      {
        data: [loanAmount, interestAmount],
        backgroundColor: ["#e60000", "#e6000050"],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false },
    },
    cutout: "70%",
  };

  function formatIndianAmount(amount) {
    return amount.toLocaleString("en-IN");
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "20px",
        maxWidth: "90%",
        margin: "20px auto",
      }}
      className="loan-calculator-container"
    >
      {/* Loan Details Section */}
      <div
        style={{
          flex: "1 1 50%",
          padding: "10px",
          boxSizing: "border-box",
        }}
      >
        <h1 style={{ fontSize: "38px", color: "#e60000" }}>EMI Calculator</h1>

        {/* Loan Amount Input */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#000",
              lineHeight: "45px",
            }}
          >
            Loan Amount (₹)
          </label>
          <input
            type="number"
            value={loanAmount}
            onChange={(e) => setLoanAmount(e.target.value)}
            style={{
              width: "95%",
              marginBottom: "-12px",
              padding: "15px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "20px",
            }}
          />
          <input
            type="range"
            min="100000"
            max="1000000"
            step="10000"
            value={loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
            style={{
              width: "100%",
              height: "4px",
              marginLeft: "-0.5px",
              background: "linear-gradient(135deg, #FF0000, #000000)",
              borderRadius: "5px",
              appearance: "none",
              marginBottom: "20px",
            }}
          />
        </div>

        {/* Interest Rate Input */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#000",
              lineHeight: "45px",
            }}
          >
            Interest Rate (%)
          </label>
          <input
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            style={{
              width: "95%",
              marginBottom: "-12px",
              padding: "15px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "20px",
            }}
          />
          <input
            type="range"
            min="1"
            max="15"
            step="0.1"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
            style={{
              width: "100%",
              height: "4px",
              marginLeft: "-0.5px",
              background: "linear-gradient(135deg, #FF0000, #000000)",
              borderRadius: "5px",
              appearance: "none",
              marginBottom: "20px",
            }}
          />
        </div>

        {/* Tenure Input */}
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#000",
              lineHeight: "45px",
            }}
          >
            Tenure (Month)
          </label>
          <input
            type="number"
            value={tenure}
            onChange={(e) => setTenure(e.target.value)}
            style={{
              width: "95%",
              marginBottom: "-12px",
              padding: "15px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "20px",
            }}
          />
          <input
            type="range"
            min="1"
            max="480"
            step="1"
            value={tenure}
            onChange={(e) => setTenure(Number(e.target.value))}
            style={{
              width: "100%",
              height: "4px",
              marginLeft: "-0.5px",
              background: "linear-gradient(135deg, #FF0000, #000000)",
              borderRadius: "5px",
              appearance: "none",
            }}
          />
        </div>
      </div>

      {/* EMI Breakdown Section */}
      <div
        style={{
          flex: "1 1 50%",
          padding: "10px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "300px",
            margin: "20px auto",
          }}
        >
          <Doughnut data={chartData} options={chartOptions} />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            <div>Your EMI is</div>
            <div style={{ color: "#e60000", fontSize: "18px" }}>
              ₹{isNaN(emi) ? 0 : formatIndianAmount(emi)}
            </div>
            <div>Per Month</div>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            marginTop: "20px",
            fontSize: "16px",
            padding: "10px",
            marginInline: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontSize: "18px", lineHeight: "25px" }}>
              {" "}
              <span
                style={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#e60000",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              ></span>
              Principal Amount
            </span>
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              ₹{formatIndianAmount(loanAmount)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontSize: "18px", lineHeight: "25px" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#e6000050",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              ></span>
              Interest Payable
            </span>
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              ₹{isNaN(interestAmount) ? 0 : formatIndianAmount(interestAmount)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginLeft: "25px",
            }}
          >
            <span style={{ fontSize: "18px", lineHeight: "25px" }}>
              Total Payment
            </span>
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              ₹{isNaN(totalPayment) ? 0 : formatIndianAmount(totalPayment)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "2px solid #ccc",
              paddingTop: "20px",
              marginTop: "10px",
              marginLeft: "25px",
            }}
          >
            <span style={{ fontSize: "18px", lineHeight: "25px" }}>
              Your EMI Amount
            </span>
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              ₹{isNaN(emi) ? 0 : formatIndianAmount(emi)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanEMICalculator;
