import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Terms from "./components/Static/Terms";
import PrivacyPolicy from "./components/Static/PrivacyPolicy";
import FAQPage from "./components/Static/FAQPage";
import Blog from "./components/Blog/Blog";
import LoanPage from "./components/LoanPage/LoanPage";
import Loan from "./components/LoanPage/Loan";
import Contact from "./components/ContactUs/Contact";
import AboutUs from "./components/About/About";
import BlogDetail from "./components/Blog/BlogDetail";
import Payroll from "./components/Payroll/Payroll";
import Application from "./components/Application/Application";

const AppContent = () => {
  const location = useLocation();

  return (
    <div className="app">
      <div className="main-content">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/loan/:id" element={<Loan />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/application" element={<Application />} />
          <Route path="/payroll" element={<Payroll />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogDetail/:id" element={<BlogDetail />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
