import React, { useEffect, useState } from "react";
import "./LoanPage.css";
import {
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { blog } from "../Blog/dummydata";
import "../Blog/blog.css";
import HomeContact from "../HomeContact/HomeContact";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

function LoanPage({
  title,
  description,
  buttonText,
  formFields,
  termsText,
  imageSrc,
  titleSecondSection,
  sectionsSecond,
}) {
  const maxLength = 150; // Limit the description to 150 characters
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlog();
  }, []);

  const fetchBlog = async () => {
    let response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/blog/getAll`
    );
    setBlogs(response.data.data.blog);
  };

  const handleNavigate = (id) => {
    navigate("/blogDetail/" + id);
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  return (
    <div className="loan-page-container">
      <div className="loancontainer">
        <div className="loan-content">
          <h1 className="loan-heading">{title}</h1>
          <p
            className="loan-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <button className="apply-button">{buttonText}</button>
        </div>
        <div className="loan-image">
          <img src={imageSrc} alt="Loan Assistance" className="loan-img" />
        </div>
      </div>

      <HomeContact />
      <h1 className="headingLoan">{titleSecondSection}</h1>

      <div
        className="loan-form-container"
        style={{
          flexDirection: "column",
          paddingTop: "20px",
          paddingBottom: "0",
        }}
      >
        {sectionsSecond?.map((section, index) => (
          <div key={index}>
            <h2 className="headingContainerLoan">{section.question}</h2>
            <div
              className="terms-boxLoan"
              dangerouslySetInnerHTML={{ __html: section.answers }}
            >
              {/* {section.texts.map((text, textIndex) => (
                <div key={textIndex} className="textLoan">
                  {text}
                </div>
              ))}
              {section.list && (
                <ul className="listLoan">
                  {section.list.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
              )} */}
            </div>
          </div>
        ))}
      </div>
      <>
        <h2 style={{ textAlign: "center", color: "#fff" }}>Related Blogs</h2>
      </>
      <section className="blog">
        <div
          className="container"
          style={{
            background: "transparent",
            border: "none",
            boxShadow: "none",
            padding: "0",
          }}
        >
          <div className="grid2">
            {blogs.slice(0, 3).map((val) => (
              <div
                className="items shadow"
                onClick={(e) => {
                  handleNavigate(val._id);
                }}
              >
                <div className="img">
                  <img src={val.thumbnail} alt="" />
                </div>
                <div className="text">
                  <div className="admin flexSB">
                    <span>
                      <label htmlFor="">{val.tag.tagName}</label>
                    </span>
                    <span>
                      <label htmlFor="">
                        {moment(val.createdAt).format("DD-MM-YYYY")}
                      </label>
                    </span>
                  </div>
                  <h1>{val.title}</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: val.shortDescription }}
                  />
                  {val.shortDescription.length > maxLength && (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavigate(val._id);
                      }}
                    >
                      Read More
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoanPage;
