import React, { useRef, useState } from "react";
import "../LoanPage/LoanPage.css";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { FaSquarePhone, FaSquareEnvelope } from "react-icons/fa6";

function Contact() {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const formRef = useRef(null);
  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/enquiry/create`,
        formValue
      );
      console.log(response.data.data.data);
      toast.success("Thank you! We will get back to you.");
      setFormValue({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      toast.error("An issue occurred. Please try again later.");
    }
  };

  const handleChange = (e) => {
    setFormValue((prev) => {
      let tmp = { ...prev };
      tmp[e.target.name] = e.target.value;
      return tmp;
    });
  };

  return (
    <div className="loan-page-container">
      <ToastContainer />
      <div className="loancontainer">
        <div className="loan-content">
          <h1 className="loan-heading">How can we help?</h1>
          <p className="loan-description">
            We are not dealing in Insurance credit card but instant loans
            from many banks and NBFCs and payroll service
            <br /> Our seasoned loan experts, with decades of experience, help
            you secure instant loans through our extensive network of partner
            banks and NBFCs and further assist in streamlining payroll
            management.
          </p>
          <button className="apply-button" onClick={scrollToForm}>
            Get in Touch
          </button>
        </div>
        <div className="loan-image">
          <img src="./contact.png" alt="Loan Assistance" className="loan-img" />
        </div>
      </div>

      <div
        className="loan-form-container"
        style={{ paddingTop: "20px" }}
        ref={formRef}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "16px", // Add consistent gap between form elements
          }}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Typography
            variant="h4"
            className="loan-form-heading"
            style={{ marginBottom: "16px", textAlign: "center" }} // Center the heading
          >
            Get In Touch
          </Typography>

          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                name="name"
                required
                type="text"
                value={formValue.name}
                onChange={(e) => {
                  console.log(e);
                  handleChange(e);
                }}
              />
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                required
                type="email"
                name="email"
                value={formValue.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>

            {/* Full Width */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                required
                type="number"
                name="phone"
                value={formValue.phone}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                variant="outlined"
                required
                multiline
                name="message"
                value={formValue.message}
                onChange={(e) => {
                  handleChange(e);
                }}
                rows={4}
              />
            </Grid>
          </Grid>

          {/* <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={handleTermsChange}
                name="terms"
                color="primary"
                required
              />
            }
            label="I accept the terms and conditions"
            style={{ marginTop: "8px" }}
          /> */}

          <div
            style={{
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            <button
              type="submit"
              className="apply-button"
              style={{
                maxWidth: "300px",
                width: "100%",
                padding: "12px 16px",
                backgroundColor: "#1976d2",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
          <div className="contact-info">
            <Typography
              variant="h5"
              className="contact-heading"
              style={{ textAlign: "center", marginBottom: "16px" }}
            >
              Contact Information
            </Typography>

            <div className="contact-details">
              <FaSquarePhone size={20} style={{ marginRight: "8px" }} />
              <span>011-43614502, 9718095441, 9718520070</span>
            </div>

            <div className="contact-details">
              <FaSquareEnvelope size={20} style={{ marginRight: "8px" }} />
              <span>bricksolutions.web@gmail.com</span>
            </div>
          </div>
        </form>
      </div>

      <div style={{ marginTop: "20px", textAlign: "center", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3501.4298049281156!2d77.2068999!3d28.646846699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM4JzQ4LjciTiA3N8KwMTInMjQuOCJF!5e0!3m2!1sen!2sin!4v1738779947666!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          title="Map Location"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
