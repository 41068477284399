import React, { useEffect, useState } from "react";
import LoanPage from "./LoanPage";
import { useParams } from "react-router-dom";
import axios from "axios";

function Loan() {
  const { id } = useParams();
  const [loan, setLoan] = useState({});

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchLoan = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loan/getAll`
      );
      response = response.data.data.loan.find((el) => el._id === id);
      console.log(response);
      setLoan(response);
    } catch (error) {
      console.log(error);
    }
  };

  const formFields = [
    { label: "Full Name", required: true },
    { label: "Phone Number", required: true },
    { label: "Email Address", required: true },
    { label: "Loan Amount", required: true, type: "number" },
    { label: "City", required: true },
    { label: "Pincode", required: true, type: "number" },
  ];

  return (
    <LoanPage
      title="Get the Loan You Need"
      description={loan.shortDescription}
      buttonText="Apply Now"
      formFields={formFields}
      termsText="You are just a step away from the best loan offer near you. Representative will contact you with the best cash loan offer as you share your contact name, email & mobile number."
      imageSrc={loan.banner}
      titleSecondSection={`Learn More About ${loan.loanName}`}
      sectionsSecond={loan.description}
    />
  );
}

export default Loan;
