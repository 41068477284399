import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import "./Hero.css";
import axios from "axios";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Hero = () => {
  const [hero, setHero] = useState([]);

  useEffect(() => {
    handleHero();
  }, []);

  const handleHero = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/hero/getAll`
      );
      setHero(response.data.data.hero);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="hero-wrapper">
      <AutoPlaySwipeableViews interval={3000} enableMouseEvents>
        {hero.map((el, index) => (
          <section className="hero-section" key={index}>
            <div className="text-container">
              <h1 className="headingHero">{el.heading}</h1>
              <p className="subheadingHero">{el.subHeading}</p>
              <button
                className="button"
                onClick={() => (window.location.href = el.ctaLink)}
              >
                {el.ctaHeading}
              </button>
            </div>
            <div className="image-container">
              <img src={el.image} alt="Hero Image" className="image" />
            </div>
          </section>
        ))}
      </AutoPlaySwipeableViews>
    </div>
  );
};

export default Hero;
