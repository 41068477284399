import React from "react";
import "./Footer.css";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img
            src="https://d2zj8ucvos3lkf.cloudfront.net/website/LogoBS.png"
            alt="Brick Solutions Logo"
            className="logoimgfooter"
          />
        </div>
        <div className="footer-section">
          <h4>About Us</h4>
          <ul>
            <li>Brick Solutions</li>
            <li>
              <a href="tel:01143614502">011-43614502</a>
            </li>
            <li>
              <a href="mailto:bricksolutions.web@gmail.com">
                bricksolutions.web@gmail.com
              </a>
            </li>
            <li
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <AddLocationIcon
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/r279h36YrrD4NEC79",
                    "_target"
                  )
                }
              />
              {/* <LinkedInIcon
                onClick={() =>
                  window.open(
                    "https://maps.app.goo.gl/r279h36YrrD4NEC79",
                    "_target"
                  )
                }
              /> */}
            </li>
          </ul>
          {/* <p>Brick Solutions</p>
          <p><a href="https://goo.gl/maps/xyz" target="_blank" rel="noopener noreferrer">View Address</a></p>
          <p>Phone: <a href="tel:01143614502">011-43614502</a></p>
          <p>Email: <a href="mailto:bricksolutions.web@gmail.com">bricksolutions.web@gmail.com</a></p> */}
        </div>
        <div className="footer-section">
          <h4>Our Policies</h4>
          <ul>
            <li>
              <a href="/terms">Terms & Conditions</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Other Services</h4>
          <ul>
            <li>
              <a href="/payroll">Payroll</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Brick Solutions. All Rights
          Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
