import React from "react";
import StaticPage from "./StaticPage";

const FAQPage = () => {
  const faqData = {
    title: "Frequently Asked Questions (FAQ)",
    sections: [
      {
        heading: "What types of loans do you offer?",
        texts: [
          "We offer a wide range of loans, including, business loans, home loans, vehicle loans, personal loans and working capital facility through our network of partner banks and NBFCs.",
        ],
        list: null,
      },
      {
        heading: "How can I apply for a loan?",
        texts: [
          "You can apply online through our website, connect our experts on call or visit our office. Our loan experts will guide you through the process and assist with documentation.",
        ],
        list: null,
      },
      {
        heading: "What are the eligibility criteria for a loan?",
        texts: [
          "Eligibility varies by loan type but generally includes factors such as income, credit score, employment status, financial strength and existing financial obligations.",
        ],
        list: null,
      },
      {
        heading: "How long does it take to get loan approval?",
        texts: [
          "Approval time depends on the loan type and lender. Some loans are approved instantly upon submission to the bank, while others may require additional verification and processing time.",
        ],
        list: null,
      },
      {
        heading: "What documents are required for a loan application?",
        texts: [
          "Commonly required documents include identity proof, address proof, income proof (salary slips, bank statements, or ITR), and business financials (for business loans).",
        ],
        list: null,
      },
      {
        heading: "Do I need a guarantor or collateral?",
        texts: [
          "This depends on the loan type. Secured loans require collateral, while unsecured loans (such as personal loans) typically do not.",
        ],
        list: null,
      },
      {
        heading: "What is the interest rate on loans?",
        texts: [
          "Interest rates vary based on loan type, lender, and applicant profile. Contact us for the latest rates and best available options.",
        ],
        list: null,
      },
      {
        heading: "Can I apply for a loan with a low credit score?",
        texts: [
          "Yes, but the approval chances may be lower, and interest rates might be higher. We can help you find suitable options even with a low credit score.",
        ],
        list: null,
      },
      {
        heading: "Is there a processing fee for loan applications?",
        texts: [
          "Most lenders charge a processing fee, which varies by bank and loan type. Our team will inform you about any applicable fees before you apply.",
        ],
        list: null,
      },
      {
        heading: "How do I repay the loan?",
        texts: [
          "Loan repayment is done in EMIs (Equated Monthly Installments) through bank auto-debit, post-dated cheques, or online transfers, depending on lender policies.",
        ],
        list: null,
      },
      {
        heading: "Can I prepay or foreclose my loan?",
        texts: [
          "Yes, most loans allow prepayment or foreclosure, but some may have charges. We can assist you in understanding the terms for early repayment.",
        ],
        list: null,
      },
      {
        heading: "What happens if I miss an EMI payment?",
        texts: [
          "Missing an EMI may result in late fees, penalty charges, and a negative impact on your credit score. Contact us if you need assistance in managing repayments.",
        ],
        list: null,
      },
    ],
  };

  return <StaticPage title={faqData.title} sections={faqData.sections} />;
};

export default FAQPage;
