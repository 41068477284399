import React, { useEffect, useState } from "react";
import "./blogDetail.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    fetchBlog(id);
  }, [id]);

  const fetchBlog = async (id) => {
    let query = "";
    let response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/blog/get/${id}`
    );
    setBlog(response.data.data.blog);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="blogdetail-page-container">
      <div
        className="blogdetail-form-container"
        style={{ flexDirection: "column", paddingBottom: "0" }}
      >
        <div>
          <h2 className="headingContainerblogdetail">{blog.title}</h2>
          <div className="terms-boxBlogDetail">
            <img
              src={blog.thumbnail}
              alt={blog.title || "Blog"}
              className="blogDetailImg"
            />
            <div
              className="textBlogDetail"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
