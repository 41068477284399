import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const OurPartners = () => {
  const scrollContainerRef = useRef(null);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (!container) return;

    let interval;
    let isHovered = false;

    // Clone the content for a seamless loop effect
    const duplicateContent = () => {
      container.innerHTML += container.innerHTML;
    };

    const startScrolling = () => {
      interval = setInterval(() => {
        if (!isHovered) {
          container.scrollLeft += container.offsetWidth / 4;

          // Reset when reaching the middle of the cloned content
          if (container.scrollLeft >= container.scrollWidth / 2) {
            container.scrollLeft = 0;
          }
        }
      }, 2000);
    };

    duplicateContent();
    startScrolling();

    container.addEventListener("mouseenter", () => (isHovered = true));
    container.addEventListener("mouseleave", () => (isHovered = false));

    return () => {
      clearInterval(interval);
      container.removeEventListener("mouseenter", () => (isHovered = true));
      container.removeEventListener("mouseleave", () => (isHovered = false));
    };
  }, []);
  useEffect(() => {
    fetchBanks();
  }, []);

  const fetchBanks = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bank/getAll`
      );
      setBanks(response.data.data.bank);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={styles.sectionContainer}>
      <h2 style={styles.heading}>Our Partners</h2>
      <div ref={scrollContainerRef} style={styles.cardContainer}>
        {banks.map((bank, index) => (
          <div key={index} style={styles.card}>
            <img
              src={bank.logo}
              alt={`Partner ${bank.bankName}`}
              style={styles.cardImage}
            />
            <div style={styles.cardDetails}>
              <p style={styles.bankName}>{bank.bankName}</p>
              <p style={styles.interestRate}>
                {bank.interest ? (
                  <>
                    Interest Rate: <span>{bank.interest}%</span>
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  sectionContainer: {
    width: "80%",
    margin: "auto",
    marginTop: "40px",
    padding: "30px",
    borderRadius: "20px",
    // background: "linear-gradient(135deg, #f3f4f6, #e5e7eb)",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  heading: {
    fontSize: "2.5rem",
    fontWeight: "700",
    marginBottom: "30px",
    color: "#1f2937",
    letterSpacing: "0.5px",
  },
  cardContainer: {
    display: "flex",
    overflow: "hidden",
    width: "100%",
    scrollBehavior: "smooth",
    gap: "20px",
    padding: "10px",
  },
  card: {
    width: "220px",
    minHeight: "220px",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
  },
  cardImage: {
    width: "200px", // 1.5x the height
    height: "100px",
    objectFit: "contain", // Ensures full image visibility
    marginBottom: "15px",
  },
  cardDetails: {
    textAlign: "center",
  },
  bankName: {
    fontSize: "1.2rem",
    fontWeight: "600",
    margin: "5px 0",
    color: "#1f2937",
    textAlign: "center",
  },
  interestRate: {
    fontSize: "1rem",
    fontWeight: "500",
    color: "#6b7280",
  },
};

export default OurPartners;
