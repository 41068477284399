import React from "react";
import "./StaticPage.css";

const StaticPage = ({ title, sections }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(116.64deg, #f37e20 32.94%, #ad1e23 136.53%)",
        paddingBottom: "30px",
      }}
    >
      <h1 className="heading">{title}</h1>
      <div className="container">
        {sections.map((section, index) => (
          <div key={index}>
            <h2
              className="headingContainer"
              dangerouslySetInnerHTML={{ __html: section.heading }}
            ></h2>
            <div className="terms-box">
              {section.texts &&
                section.texts.map((text, textIndex) => (
                  <div key={textIndex} className="text">
                    {text}
                  </div>
                ))}
              {section.list && (
                <ul className="list">
                  {section.list.map((item, itemIndex) => (
                    <li
                      key={itemIndex}
                      dangerouslySetInnerHTML={{ __html: item }}
                    ></li>
                  ))}
                </ul>
              )}
              {section.detailList && (
                <ul className="list">
                  {section.detailList.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      {/* Wrap heading in a separate element */}
                      {item.heading && (
                        <span
                          dangerouslySetInnerHTML={{ __html: item.heading }}
                        />
                      )}

                      {item.details.length > 0 && (
                        <ul className="list">
                          {item.details.map((detail, detailIndex) => (
                            <li key={detailIndex}>{detail}</li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticPage;
