import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoanSection = () => {
  const [loan, setLoan] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchLoan = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loan/getAll`
      );
      setLoan(response.data.data.loan);
    } catch (error) {
      console.log(error);
    }
  };

  const containerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
    gap: "20px",
    padding: "20px",
  };

  const cardStyle = {
    padding: "20px",
    // maxWidth:"250px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    fontSize: "2rem",
    width: "70px",
    height: "70px",
    borderRadius: "10px",
    background: "rgb(255,232,252)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "2rem",
          fontWeight: "600",
          color: "#000",
        }}
      >
        Our Services
      </h2>
      <div style={containerStyle}>
        {loan.map((card, index) => (
          <div
            key={index}
            style={{
              ...cardStyle,
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
            onClick={() => {
              navigate(`/loan/${card._id}`);
            }}
          >
            <div style={iconStyle}>
              <img
                src={card.logo}
                style={{
                  width: "60%",
                  maxHeight: "auto",
                }}
              />
            </div>
            <h3
              style={{
                textAlign: "center",
              }}
            >
              {card.loanName}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoanSection;
