import React, { useState } from "react";
import "../LoanPage/LoanPage.css";
import {
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const Payroll = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/enquiry/create`,
        formValue
      );
      console.log(response.data.data.data);
      toast.success("Thank you! We will get back to you.");
      setFormValue({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      toast.error("An issue occurred. Please try again later.");
    }
  };

  const handleChange = (e) => {
    setFormValue((prev) => {
      let tmp = { ...prev };
      tmp[e.target.name] = e.target.value;
      return tmp;
    });
  };
  const aboutData = {
    titleSection: "About Us",
    sections: [
      {
        heading: "End-to-End Payroll Solutions – Zero Hassle for You",
        texts: [
          "At Brick Solutions, we specialize in providing comprehensive payroll management services that take the burden off your shoulders. Our expert team ensures smooth and accurate payroll processing, so you can focus on what truly matters – growing your business.",
        ],
        list: null,
      },
      {
        heading: "Why Choose Us?",
        texts: null,
        list: [
          {
            heading: "Hands-On Expertise:",
            description:
              "Our dedicated team manages every aspect of payroll, ensuring precision and compliance without relying on automated tools.",
          },
          {
            heading: "Hassle-Free Service:",
            description:
              "We take full ownership of the process, leaving you with zero headaches and complete peace of mind.",
          },
          {
            heading: "Tailored Solutions:",
            description:
              "Every business is unique, and we customize our services to meet your specific payroll requirements.",
          },
          {
            heading: "Compliance Guaranteed:",
            description:
              "Rest assured that your payroll complies with all statutory and legal requirements.",
          },
        ],
      },
      {
        heading: "Our Services",
        texts: null,
        list: [
          {
            heading: "Payroll Processing:",
            description:
              "Accurate salary calculations, payslip generation, and timely disbursement – all handled seamlessly by our team.",
          },
          {
            heading: "Taxation and Compliance:",
            description:
              "We manage TDS filings, EPF, ESI, and other statutory obligations with precision, keeping your business compliant.",
          },
          {
            heading: "Attendance and Leave Management Support:",
            description:
              "Integrating your attendance and leave data into payroll calculations for error-free salary processing.",
          },
          {
            heading: "Full & Final Settlements:",
            description:
              "Smooth processing of employee exits, including gratuity, leave encashment, and final settlements.",
          },
          {
            heading: "Customized Payroll Reports:",
            description:
              "Get detailed insights into your payroll data with reports tailored to your needs.",
          },
          {
            heading: "Dedicated Support Team:",
            description:
              "Our payroll specialists are always available to answer your queries and ensure smooth operations.",
          },
        ],
      },
      {
        heading: "Our Services",
        texts: null,
        list: [
          {
            heading: "End-to-End Responsibility:",
            description:
              "From processing salaries to handling compliance, we take care of it all, so you don’t have to.",
          },
          {
            heading: "Data Security:",
            description:
              "Your payroll data is handled with utmost confidentiality and integrity.",
          },
          {
            heading: "Stress-Free Experience:",
            description:
              "Leave the complexities of payroll to us and enjoy a worry-free service.",
          },
        ],
      },
      {
        heading: "Industries We Serve",
        texts: [
          "We provide payroll management services to businesses across industries, including:",
        ],
        list: [
          "IT and Software",
          "Retail and E-commerce",
          "Manufacturing",
          "Startups and SMEs",
          "Healthcare",
          "Banking and Finance",
        ],
      },
      {
        heading: "Let Us Handle Your Payroll",
        texts:
          "Managing payroll doesn’t have to be a headache. With Brick Solutions, you get reliable, accurate, and hassle-free payroll services.",
        list: [
          {
            heading: "Contact Us:",
            description: "7988300480",
          },
          {
            heading: "Email:",
            description: "CA.hbateam@gmail.com",
          },
        ],
      },
    ],
  };

  return (
    <div className="loan-page-container">
      <ToastContainer />
      <div className="loancontainer">
        <div className="loan-content">
          <h1 className="loan-heading">Payroll Management Solutions</h1>
          <p className="loan-description">
            At Brick Solutions, we provide financial consultancy, loan
            services, and payroll solutions tailored to meet the diverse needs
            of our clients.
          </p>
          <p className="loan-description">
            We pride ourselves on delivering reliable, efficient, and customized
            services to help businesses and individuals achieve their financial
            goals.
          </p>
        </div>
        <div className="loan-image">
          <img
            src="./Payroll.svg"
            alt="Payroll Management"
            className="loan-img"
          />
        </div>
      </div>
      <div className="loan-form-container">
        <Box className="loan-form-box">
          <div className="contactformfirst">
            <Typography variant="h4" className="loan-form-heading">
              Get in Touch for <br />
              Payroll Solutions
            </Typography>
            <Typography className="loan-form-description">
              We're here to help you streamline your payroll process. Share your
              contact details and let us know your payroll needs, and one of our
              specialists will get in touch with you.
            </Typography>
          </div>
        </Box>
        <Box className="loan-form-box">
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px", // Add consistent gap between form elements
            }}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Typography
              variant="h4"
              className="loan-form-heading"
              style={{ marginBottom: "16px", textAlign: "center" }} // Center the heading
            >
              Get In Touch
            </Typography>

            <Grid container spacing={2}>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  name="name"
                  required
                  type="text"
                  value={formValue.name}
                  onChange={(e) => {
                    console.log(e);
                    handleChange(e);
                  }}
                />
              </Grid>

              {/* Right Column */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  required
                  type="email"
                  name="email"
                  value={formValue.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>

              {/* Full Width */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  required
                  type="number"
                  name="phone"
                  value={formValue.phone}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  required
                  multiline
                  name="message"
                  value={formValue.message}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  rows={4}
                />
              </Grid>
            </Grid>

            {/* <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={handleTermsChange}
                name="terms"
                color="primary"
                required
              />
            }
            label="I accept the terms and conditions"
            style={{ marginTop: "8px" }}
          /> */}

            <div
              style={{
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              <button
                type="submit"
                className="apply-button"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  padding: "12px 16px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </div>
      <div
        className="loan-form-container"
        style={{
          flexDirection: "column",
          paddingTop: "20px",
          paddingBottom: "0",
        }}
      >
        {aboutData.sections.map((section, index) => (
          <div key={index}>
            <h2 className="headingContainerLoan">{section.heading}</h2>
            <div className="terms-boxLoan">
              {/* Check if texts is an array */}
              {Array.isArray(section.texts) &&
                section.texts.map((text, textIndex) => (
                  <div key={textIndex} className="textLoan">
                    {text}
                  </div>
                ))}
              {/* If texts is not an array (could be a string or null), render it directly */}
              {typeof section.texts === "string" && (
                <div className="textLoan">{section.texts}</div>
              )}
              {section.list && (
                <ul className="listLoan">
                  {section.list.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      <strong>{item.heading}</strong> {item.description || item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Payroll;
