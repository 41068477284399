import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import "./Application.css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import moment from "moment";
import FullPageLoader from "../Loader";

function Application() {
  const [applicationId, setApplicationId] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [documentList, setDocumentList] = useState();
  const [fileUploads, setFileUploads] = useState({});
  const [userData, setUserData] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mockDocumentList = [
    { name: "Proof of Identity", type: "image/*" },
    { name: "Proof of Address", type: "image/*" },
    { name: "Application Form", type: "application/pdf" },
  ];

  const sendOtp = async () => {
    setIsLoading(true);
    if (applicationId) {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/application/sendOtp`,
          {
            applicationId: applicationId,
          }
        );
        setIsOtpSent(true);
        toast.success("OTP has been sent to your registered email.");
      } catch (error) {
        toast.error("An issue occurred. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Please enter a valid Application ID.");
    }
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    if (otp) {
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/application/verifyOtp`,
          {
            applicationId: applicationId,
            otp: otp,
          }
        );
        toast.success("OTP Verified.");
        console.log(response.data.data.data);
        setUserData(response.data.data.data);
        let documentsDetail = response.data.data.data.documents.map((el) => {
          return {
            name: el.docName,
            type: "*",
            _id: el._id,
          };
        });
        setDocumentList(documentsDetail);
        setIsOtpVerified(true);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      toast.error("Invalid OTP.");
    }
  };

  const handleFileChange = (e, docName, index) => {
    let dis = true;
    setDocumentList((prevState) => {
      let tmp = [...prevState];
      tmp[index].file = "";
      console.log(tmp);
      const isAnyFileMissing = tmp.some(
        (doc) => !doc.file || doc.file.trim() === ""
      );
      dis = isAnyFileMissing;
      return tmp;
    });
    // setIsSubmitDisabled(dis);
    const file = e.target.files[0];
    if (file) {
      setFileUploads({
        ...fileUploads,
        [docName]: file,
      });
    }
  };

  const handleDocumentUpload = async (docName, index) => {
    setIsLoading(true);
    console.log(fileUploads);
    if (!fileUploads[docName]) {
      setIsLoading(false);
      toast.error(`Please select a file for ${docName}.`);
      return;
    }

    try {
      let form = new FormData();
      form.append(`folder`, userData.loanId);
      form.append(`file`, fileUploads[docName]);
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upload`,
        form
      );
      console.log(response.data.fileUrl);
      let dis = true;
      setDocumentList((prevState) => {
        let tmp = [...prevState];
        tmp[index].file = response.data.fileUrl;
        const isAnyFileMissing = tmp.some(
          (doc) => !doc.file || doc.file.trim() === ""
        );
        dis = isAnyFileMissing;
        return tmp;
      });
      // setIsSubmitDisabled(dis);
      toast.success(`${docName} uploaded successfully!`);
      setFileUploads({ ...fileUploads, [docName]: null });
    } catch (error) {
      console.log(error);
      toast.error("An issue occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmission = async () => {
    setIsLoading(true);
    try {
      let document = documentList.map((el) => {
        return {
          _id: el._id,
          file: el.file,
        };
      });
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/application/document`,
        {
          applicationId: userData.loanId,
          documents: document,
        }
      );
      toast.success("Document Submitted!");
      setApplicationId("");
      setOtp("");
      setIsOtpSent(false);
      setIsOtpVerified(false);
      setDocumentList();
      setFileUploads({});
      setUserData({});
      setIsSubmitDisabled(true);
    } catch (error) {
      toast.error("An issue occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="applicationdetail-page-container"
      style={{ padding: "20px" }}
    >
      {isLoading && <FullPageLoader />}
      <ToastContainer />
      <div className="applicationdetail-form-container">
        <div>
          <Typography variant="h5" align="center" gutterBottom>
            Application Status
          </Typography>

          {!isOtpVerified && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginBottom: "10px" }}
              >
                <TextField
                  label="Application ID"
                  variant="outlined"
                  value={applicationId}
                  onChange={(e) => setApplicationId(e.target.value)}
                  disabled={isOtpSent}
                  style={{ marginRight: "10px", width: "250px" }}
                />
                <Button
                  variant="contained"
                  onClick={sendOtp}
                  disabled={isOtpSent}
                  style={{ width: "140px" }}
                >
                  Send OTP
                </Button>
              </Box>

              {isOtpSent && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: "20px" }}
                >
                  <TextField
                    label="Enter OTP"
                    variant="outlined"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{
                      marginTop: "10px",
                      marginRight: "10px",
                      width: "250px",
                    }}
                    disabled={!isOtpSent}
                  />
                  <Button
                    variant="contained"
                    onClick={verifyOtp}
                    disabled={!otp || otp.length !== 6}
                    style={{ width: "140px" }}
                  >
                    Verify OTP
                  </Button>
                </Box>
              )}
            </>
          )}

          {isOtpVerified && (
            <div className="application-detail">
              <Grid container spacing={1} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Application ID:</strong> {userData.loanId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Name:</strong> {userData.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Email:</strong> {userData.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Phone:</strong> {userData.phone}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Type:</strong> {userData.type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Current Status:</strong> {userData.status}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Logs
              </Typography>
              {userData.logs && userData.logs.length > 0 ? (
                <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                  <Table aria-label="logs table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Date
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Reason
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData.logs.map((log) => (
                        <TableRow key={log._id}>
                          <TableCell>
                            {new Date(log?.dateTime).toLocaleString()}
                          </TableCell>
                          <TableCell>{log.status}</TableCell>
                          <TableCell>
                            {log.reason ? log.reason.slice(0, 80) : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No logs available.
                </Typography>
              )}
            </div>
          )}

          {userData.status === "Documents Requested" && (
            <div className="terms-boxapplicationDetail">
              <Typography variant="h6" gutterBottom>
                Documents to Upload:
              </Typography>

              {documentList.length > 0 ? (
                <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                  <Table aria-label="document upload table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Document
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Upload
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Selected File
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {documentList.map((doc, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {doc.name}
                          </TableCell>
                          <TableCell style={{ minWidth: "150px" }}>
                            <label
                              htmlFor={`file-upload-${index}`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "120px",
                                padding: "8px 12px",
                                backgroundColor: "#f37e20", // Modern red theme
                                color: "white",
                                borderRadius: "20px",
                                cursor: "pointer",
                                fontSize: "12px",
                                fontWeight: "500",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
                                transition: "all 0.2s ease",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.backgroundColor = "#f37e20")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.backgroundColor = "#f37e20")
                              }
                            >
                              <span style={{ marginRight: "6px" }}>📁</span>
                              Upload
                              <input
                                id={`file-upload-${index}`}
                                type="file"
                                accept={doc.type}
                                onChange={(e) =>
                                  handleFileChange(e, doc.name, index)
                                }
                                style={{
                                  display: "none",
                                }}
                              />
                            </label>
                          </TableCell>
                          <TableCell>
                            {doc.file ? (
                              <span
                                style={{ cursor: "pointer", color: "#0000FF" }}
                                onClick={() => {
                                  window.open(doc.file, "_target");
                                }}
                              >
                                View
                              </span>
                            ) : fileUploads[doc.name] ? (
                              fileUploads[doc.name].name
                            ) : (
                              "No file selected"
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleDocumentUpload(doc.name, index)
                              }
                              disabled={!fileUploads[doc.name]}
                            >
                              Upload
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No documents found to upload.
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginTop: "10px",
                }}
                disabled={isSubmitDisabled}
                onClick={() => handleSubmission()}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Application;
