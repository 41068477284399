import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import axios from "axios";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false); // State for handling sub-menu toggle
  const servicesMenuRef = useRef(null); // Reference for the Services menu
  const otherServicesMenuRef = useRef(null); // Reference for the Services menu
  const mobileMenuRef = useRef(null); // Reference for the mobile menu
  const [loan, setLoan] = useState([]);

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchLoan = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loan/getAll`
      );
      setLoan(response.data.data.loan);
    } catch (error) {
      console.log(error);
    }
  };

  // Close sub-menu if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        servicesMenuRef.current &&
        otherServicesMenuRef.current &&
        !servicesMenuRef.current.contains(event.target) &&
        !otherServicesMenuRef.current.contains(event.target) &&
        !event.target.closest(".hamburger") &&
        !event.target.closest(".services")
      ) {
        setIsServicesOpen(false); // Close sub-menu if clicked outside
      }

      // Close mobile menu if clicked outside of it
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".hamburger")
      ) {
        setIsMobileMenuOpen(false); // Close mobile menu
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleServicesMenu = (e) => {
    e.stopPropagation(); // Prevent event from propagating to the document
    setIsServicesOpen(!isServicesOpen); // Toggle sub-menu visibility
  };

  return (
    <header className="header">
      <div className="logo">
        <h1>
          <a href="/" style={{ textDecoration: "none", color: "#e60000" }}>
            <img
              src="https://d2zj8ucvos3lkf.cloudfront.net/website/LogoBS.png"
              className="logoImg"
            />
          </a>
        </h1>
      </div>

      <nav
        className={`nav ${isMobileMenuOpen ? "open" : ""}`}
        ref={mobileMenuRef}
      >
        <ul className="nav-list">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/blogs">Blog</a>
          </li>
          <li>
            <a href="/application">Application Status</a>
          </li>
          <li className="services">
            <a href="#services">
              Services
              {/* <span className="arrow"></span> Arrow icon */}
            </a>
            <ul className="sub-menu" ref={servicesMenuRef}>
              {loan.map((el) => {
                return (
                  <li>
                    <a href={`/loan/${el._id}`}>{el.loanName}</a>
                  </li>
                );
              })}
            </ul>
          </li>

          <li className="services">
            <a href="#services">
              Other Services
              {/* <span className="arrow"></span> Arrow icon */}
            </a>
            <ul className="sub-menu" ref={otherServicesMenuRef}>
              <li>
                <a href={`/payroll`}>Payroll</a>
              </li>
            </ul>
          </li>

          <li>
            <a href="/contact-us">Contact</a>
          </li>
        </ul>
      </nav>

      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
