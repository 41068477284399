import React from "react";
import StaticPage from "./StaticPage";

const PrivacyPolicy = () => {
  const privacyPolicyData = {
    title: "Privacy Policy",
    sections: [
      {
        heading: "Terms of Use and Privacy Policy",
        texts: [
          `Brick Solutions (\"we,\" \"us,\" or \"our\") is committed to protecting the privacy of your personal, financial, and payroll-related data. This Privacy Policy outlines how we collect, use, and safeguard the information you provide while using our loan and payroll processing services. By accessing our services, you consent to the practices described herein.`,
        ],
        list: null,
      },
      {
        heading: "Scope of Policy",
        texts: [
          "This policy applies to individuals and organizations engaging with our loan and payroll solutions. It governs data collected through our website, mobile applications, and any related interactions.",
        ],
        list: null,
      },
      {
        heading: "Information We Collect",
        texts: ["We collect the following types of information:"],
        list: [
          "<b>Personal and Financial Data (Loan Services):</b> Full name, contact information, date of birth, address, employment and income details, credit history, financial records,bank account and any other relevant information/documents for disbursement and repayment.",
          "<b>Payroll Data (Payroll Services):</b> Employee details such as name, contact, job role, salary structure, attendance data, statutory compliance information (e.g., PF, ESI), and bank account details for salary processing.",
          "<b>Automatic Information:</b> IP address, browser type, device information collected via cookies or system logs.",
          "<b>Regulatory and Compliance Data:</b> KYC details such as Aadhaar, PAN, or GST as required by law.",
          "<b>Permissions:</b> Access to device features such as camera, location, and microphone with explicit consent.",
        ],
      },
      {
        heading: "How We Use Your Information",
        texts: ["We use the collected information for the following purposes:"],
        list: [
          "<b>Loan Services:</b> Assessing loan eligibility, processing applications, disbursing loans, facilitating repayments, or any other aligned purposes and sharing data with credit bureaus, financial partners, and regulatory authorities.",
          "<b>Payroll Services:</b> Ensuring accurate salary computation and disbursement, managing statutory filings (e.g., PF, ESI, income tax), and responding to payroll-related queries.",
          "<b>General Usage:</b> Providing personalized services, improving user experience, sending notifications about service updates, promotions, and offers, preventing fraud, and enhancing security.",
          "<b>Regulatory Compliance:</b> Meeting legal obligations and adhering to guidelines issued by regulatory bodies (e.g., RBI, Income Tax Department).",
        ],
      },
      {
        heading: "Data Sharing and Disclosure",
        texts: [
          "We share your information only under the following circumstances:",
        ],
        list: [
          "<b>With Your Consent:</b> For loan disbursement or payroll processing with authorized financial institutions, banks, or statutory bodies.",
          "<b>With Trusted Partners:</b> Sharing limited data with vendors for loan verification, payroll software integration, or support services under confidentiality agreements.",
          "<b>Legal and Compliance Requirements:</b> Providing information to regulatory authorities when mandated by law.",
        ],
      },
      {
        heading: "Your Rights and Choices",
        texts: [
          "You have the following rights regarding your personal information:",
        ],
        list: [
          "<b>Access and Correction:</b> You can review and update your data by contacting us.",
          "<b>Consent Withdrawal:</b> You can withdraw consent for the use of sensitive information, though this may limit service availability.",
          "<b>Opt-Out Options:</b> Adjust preferences to stop receiving marketing messages or promotional content.",
        ],
      },
      {
        heading: "Grievance Redressal",
        texts: [
          "For concerns regarding data privacy or discrepancies, please contact our Grievance Officer:",
          "Grievance Officer, Brick Solutions",
          "A-203-204-205, R.G Complex, Motia Khan, Paharganj, New Delhi-110055",
          "bricksolutions.web@gmail.com",
        ],
        list: null,
      },
      {
        heading: "Changes to This Policy",
        texts: [
          "We may update this Privacy Policy periodically to align with regulatory changes or business practices.",
          "Please review this policy regularly for updates.",
          "Effective Date: 14 Febuary 2025",
        ],
        list: null,
      },
    ],
  };

  return (
    <StaticPage
      title={privacyPolicyData.title}
      sections={privacyPolicyData.sections}
    />
  );
};

export default PrivacyPolicy;
