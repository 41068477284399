import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import "../LoanPage/LoanPage.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function HomeContact() {
  const [loan, setLoan] = useState([]);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    phone: "",
    type: "",
    message: "",
  });

  useEffect(() => {
    fetchLoan();
  }, []);

  const fetchLoan = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loan/getAll`
      );
      response = response.data.data.loan.map((el) => el.loanName);
      console.log(response);
      setLoan(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/application/create`,
        formValue
      );
      console.log(response.data.data.data);
      toast.success("Thank you! Check your email for the application number.");
      setFormValue({
        name: "",
        email: "",
        phone: "",
        type: "",
        message: "",
      });
    } catch (error) {
      toast.error("An issue occurred. Please try again later.");
    }
  };

  const handleChange = (e) => {
    setFormValue((prev) => {
      let tmp = { ...prev };
      tmp[e.target.name] = e.target.value;
      return tmp;
    });
  };
  return (
    <div id="contact">
      <ToastContainer />
      <div className="loan-form-container" style={{ paddingTop: "20px" }}>
        <Box className="loan-form-box">
          <div className="contactformfirst">
            <Typography variant="h4" className="loan-form-heading">
              Your ideal loan is just a click away – fast, easy, and Tailored
              for you!.
            </Typography>
            <Typography className="loan-form-description">
              Unlock the best loan options tailored for you. You're just one
              step away from receiving the most competitive loan offers
              solutions, all tailored to meet the diverse needs of the clients.
              Share your name, email, and mobile number, and a representative
              will reach out with the best loan options available.
            </Typography>
          </div>
        </Box>
        <Box className="loan-form-box">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                  required
                  type="text"
                  value={formValue.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  required
                  type="email"
                  value={formValue.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  variant="outlined"
                  required
                  type="number"
                  value={formValue.phone}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>Loan Type</InputLabel>
                  <Select
                    label="Loan Type"
                    defaultValue=""
                    name="type"
                    value={formValue.type}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                    MenuProps={{
                      disableScrollLock: true, // Prevent scrollbar from hiding
                    }}
                  >
                    {loan.map((el, index) => (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  variant="outlined"
                  required
                  multiline
                  rows={3}
                  value={formValue.message}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                className="buttoncont"
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  variant="contained"
                  fullWidth
                  className="apply-button"
                  style={{ maxWidth: "300px", width: "100%" }}
                >
                  Submit
                </button>
              </div>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
}

export default HomeContact;
